@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css");

@import "/node_modules/react-toastify/dist/ReactToastify.css";

@layer base {
  html {
    font-family: "Inter", sans-serif;
    overflow-y: scroll;
  }

  body {
    background-color: #f5f5f5;
  }
}

@layer utilities {
  .custom-scroll::-webkit-scrollbar {
    width: 2px;
    background-color: #f1f1f1;
    height: 8px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
    background-color: #d1d5db;
  }

  .bg-linear {
    background: linear-gradient(to right, #f3f1f9 0%, #9490ca 35.39%, #4f4999 77.74%);
  }

  .bg-linear-l {
    background: linear-gradient(to left, #f3f1f9 0%, #9490ca 35.39%, #4f4999 77.74%);
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 40px;
    border-color: transparent transparent transparent #4f4999;
  }

  .triangle-gray {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 40px;
    border-color: transparent transparent transparent #c8c2e2;
  }

  .triangle-white {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 40px;
    border-color: transparent transparent transparent white;
  }

  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
    outline: none;
    border: none;
  }

  .custom-file-input::before {
    content: "🖊️";
    display: inline-block;
    background: white;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
    border-color: transparent;
  }

  .custom-file-input:hover::before {
    border-color: transparent;
  }

  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
}

.filepond--drop-label {
  background: white !important;
  border-top: 2px dotted #ebebeb !important;
  border-bottom: 1px solid #ebebeb !important;
  border-left: 1px solid #ebebeb !important;
  border-right: 1px solid #ebebeb !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.filepond--credits {
  display: none;
}

.filepond--root,
.filepond--panel .filepond--panel-root {
  background: white !important;
}
